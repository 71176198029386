import Loader from "../../../../components/loader";
import Select from "../../../../components/select";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  RestrictionTypes,
  billingRestrictionModalChange,
  updateCurrentPlan,
} from "../../../billing/billingSlice";
import {
  articleModalChange,
  getArticle,
  patchArticle,
  rewriteArticle,
  rewriteArticleResult,
  selectArticleModal,
  selectArticleResults,
  selectRewriteArticleState,
} from "../../dashboardSlice";
import { usePolling } from "../../hooks";
import { isErrorStatus } from "../../utils";
import FullsizeModal from "../fullsize-modal";
import LanguageSelect from "../language-select";
import ResponsiveButton from "../responsive-button";
import WritingStyleSelect from "../writing-style-select";
import ArticleResult from "./article-result";
import ArticleModalItemsList from "./items-list";
import styled from "@emotion/styled";
import { last, map } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Modal = styled(FullsizeModal)(
  {
    margin: "20px auto",
  },
  ({ theme: { media } }) => ({
    [media.sm]: {
      margin: "70px auto",
    },
    ".icon-close": {
      top: 20,
      right: 15,
      [media.sm]: {
        top: 30,
        right: 25,
      },
      [media.md]: {
        top: 35,
        right: 40,
      },
    },
  }),
);

const Container = styled.div(
  {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 0,
  },
  ({ theme: { colors, media } }) => ({
    color: colors.black,
    [media.sm]: {
      gap: 40,
    },
    [media.md]: {
      flexDirection: "row",
      gap: 60,
    },
  }),
);

const Header = styled.span({ fontWeight: 500 }, ({ theme: { fontSize } }) => ({
  ...fontSize.lg,
}));

const NotesHeader = styled(Header)(
  { display: "none" },
  ({ theme: { media } }) => ({
    [media.md]: {
      display: "block",
    },
  }),
);

const Notes = styled.div({}, ({ theme: { media } }) => ({
  [media.md]: { flexBasis: 396, maxWidth: 396 },
}));

const TextTitle = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

const Selects = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  ({ theme: { media } }) => ({ [media.sm]: { gap: 15 } }),
);

const LanguageSelects = styled.div(
  { display: "flex", flexWrap: "wrap", flexDirection: "column", gap: 10 },
  ({ theme: { media } }) => ({ [media.sm]: { flexDirection: "row", gap: 15 } }),
);

const SelectLabel = styled.label`
  margin-right: 15px;
`;

const Rewrite = styled.div(
  {
    alignSelf: "start",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 20,
  },
  ({ theme: { media } }) => ({
    [media.sm]: { flexDirection: "row", alignItems: "center" },
  }),
);

const RewriteButton = styled(ResponsiveButton)({
  order: 2,
});

const LoaderContainer = styled.div(
  {
    order: 1,
    display: "flex",
    alignItems: "center",
    gap: 15,
    fontWeight: 500,
  },
  ({ theme: { colors, media, fontSize } }) => ({
    color: colors.purple,
    ...fontSize.sm,
    [media.sm]: {
      order: 3,
      fontSize: 18,
      lineHeight: "20px",
    },
  }),
);

const ArticleModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const article = useAppSelector(selectArticleModal);
  const articleResults = useAppSelector(selectArticleResults);
  const rewriteState = useAppSelector(selectRewriteArticleState);

  const loading = article?.id
    ? rewriteState[article.id] ||
      (article.status !== "ready" && !isErrorStatus(article.status))
    : false;

  const polling = useCallback(() => {
    if (article?.id) dispatch(getArticle(article.id));
  }, [article?.id, dispatch]);

  usePolling(polling, loading);

  const results = useMemo(
    () => (article ? articleResults[article.id] : []),
    [article, articleResults],
  );

  const [rewriteFrom, setRewriteFrom] = useState("notes");

  const rewriteFromOptions = useMemo(
    () => [
      { value: "notes", label: t("dashboard.fromNotes") },
      { value: "last-text", label: t("dashboard.last-text") },
    ],
    [t],
  );

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        dispatch(articleModalChange(null));
      }
    },
    [dispatch],
  );

  const onRewrite = useCallback(async () => {
    if (!article) return;

    let canRewrite = true;
    try {
      const { isCharactersExceeded } = await dispatch(
        updateCurrentPlan(),
      ).unwrap();
      canRewrite = !isCharactersExceeded;
    } catch (error) {}

    if (!canRewrite) {
      dispatch(
        billingRestrictionModalChange({
          open: true,
          type: RestrictionTypes.characters,
        }),
      );
      return;
    }
    if (rewriteFrom === "notes") {
      dispatch(rewriteArticle(article.id));
    } else {
      const lastResult = last(results);
      if (lastResult) {
        dispatch(
          rewriteArticleResult({
            article_id: article.id,
            articleResult: lastResult,
          }),
        );
      }
    }
  }, [article, results, rewriteFrom, dispatch]);

  if (!article) return null;

  const { input_language, output_language, style } = article;

  return (
    <Modal open={!!article} onOpenChange={onOpenChange}>
      <Container>
        <Notes>
          <NotesHeader>{t("dashboard.notes")}</NotesHeader>
          <ArticleModalItemsList article={article} />
        </Notes>
        <TextTitle>
          <Header>{article.name}</Header>
          {map(results, (result) => {
            return (
              <ArticleResult
                key={result.id}
                articleResult={result}
                loading={loading}
                hideDelete={results.length === 1}
              />
            );
          })}
          <Selects>
            <LanguageSelects>
              <LanguageSelect
                type="input"
                value={input_language}
                onChange={(input_language) => {
                  dispatch(
                    patchArticle({
                      article,
                      change: { input_language },
                    }),
                  );
                }}
                disabled={loading}
              />
              <LanguageSelect
                type="output"
                value={output_language}
                inputValue={input_language}
                onChange={(output_language) => {
                  dispatch(
                    patchArticle({
                      article,
                      change: { output_language },
                    }),
                  );
                }}
                disabled={loading}
              />
            </LanguageSelects>
            <WritingStyleSelect
              value={style}
              onChange={(style) => {
                dispatch(
                  patchArticle({
                    article,
                    change: { style },
                  }),
                );
              }}
              disabled={loading}
              filterLanguageValue={output_language}
              articleId={article.id}
            />
            <div>
              <SelectLabel>{t("dashboard.toRewriteFrom")}</SelectLabel>
              <Select
                value={rewriteFrom}
                onChange={setRewriteFrom}
                options={rewriteFromOptions}
                ariaLabel={t("dashboard.toRewriteFrom")}
                disabled={loading}
              />
            </div>
          </Selects>
          <Rewrite>
            <RewriteButton disabled={loading} onClick={onRewrite}>
              <i className="icon-rewrite" />
              {t("rewrite")}
            </RewriteButton>
            {loading && (
              <LoaderContainer>
                <Loader />
                {t("dashboard.isCreating")}
              </LoaderContainer>
            )}
          </Rewrite>
        </TextTitle>
      </Container>
    </Modal>
  );
};

export default ArticleModal;
