import DeleteConfirm from "../../../../components/delete-confirm";
import Loader from "../../../../components/loader";
import Retry from "../../../../components/retry";
import { useAppDispatch } from "../../../../store/hooks";
import { getStyledScrollbar } from "../../../../styles/scrollbar";
import { USETIFUL_STEP_CLASSNAMES } from "../../../../utils/usetiful";
import {
  ArticleItem as IArticleItem,
  ArticleItemType,
  deleteArticleItem,
  patchArticleItem,
  articleItemModalChange,
  patchArticleItemResult,
  getArticleItem,
  retryArticleItem,
} from "../../dashboardSlice";
import { usePolling } from "../../hooks";
import { isErrorStatus } from "../../utils";
import { ArticleItemTextArea } from "../add-note/add-text-note-form";
import AudioPanel from "../audio-panel";
import styled from "@emotion/styled";
import { find, toString } from "lodash";
import {
  useCallback,
  useState,
  KeyboardEvent,
  useRef,
  memo,
  useEffect,
} from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

interface ArticleItemProps {
  articleItem: IArticleItem;
  index: number;
}

export interface ArticleItemDragItem {
  id: number;
  article_id: number;
}

const Item = styled.div<{ type: ArticleItemType; isEditing?: boolean }>(
  {
    minHeight: 50,
    maxHeight: 303,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    padding: 15,
    borderRadius: 5,
  },
  ({
    theme: {
      colors,
      fontSize: { lg, md },
    },
    type,
    isEditing,
  }) => ({
    fontSize: type === "subtitle" ? lg.fontSize : md.fontSize,
    lineHeight: type === "subtitle" ? lg.lineHeight : md.lineHeight,
    fontWeight: type === "subtitle" ? 500 : 400,
    color: colors.black,
    background: colors.lightgrey2,
    ":hover": {
      ".control": { display: isEditing ? "none" : "flex" },
    },
  }),
);

const ItemText = styled.div<{ fullSize: boolean; type: ArticleItemType }>(
  {
    flexGrow: 1,
    whiteSpace: "pre-wrap",
    cursor: "pointer",
  },
  ({ theme: { colors }, fullSize, type }) => ({
    marginTop: type === "text" || type === "subtitle" ? 0 : 15,
    overflow: fullSize ? "auto" : "hidden",
    ...getStyledScrollbar(colors),
  }),
);

const TextAreaContainer = styled.div<{ type: ArticleItemType }>(
  {},
  ({ type }) => ({
    marginTop: type === "text" || type === "subtitle" ? 0 : 15,
  }),
);

const TextArea = styled(ArticleItemTextArea)({
  padding: 0,
});

const Gradient = styled.div({
  height: "calc(100% - 198px)",
  position: "absolute",
  bottom: 15,
  left: 15,
  right: 15,
  background:
    "linear-gradient(180deg, rgba(244, 243, 251, 0.00) 0%, #F4F3FB 100%, rgba(244, 243, 251, 0.90) 100%)",
});

const Hide = styled.div(
  { marginTop: 10, cursor: "pointer" },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.purple,
    ...fontSize.md,
  }),
);

const Control = styled.div({
  width: 33,
  position: "absolute",
  top: 0,
  right: -33,
  display: "none",
  alignItems: "end",
  flexDirection: "column",
  gap: 3,
});

const ControlIcon = styled.i(
  {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    borderRadius: 5,
    cursor: "pointer",
  },
  ({ theme: { colors } }) => ({
    color: colors.purple,
    background: colors.lightblue,
  }),
);

const LoaderContainer = styled.div(
  {
    display: "flex",
    alignItems: "center",
    gap: 15,
    fontWeight: 500,
  },
  ({ theme: { colors, fontSize } }) => ({
    color: colors.purple,
    ...fontSize.md,
  }),
);

const ArticleItem = memo<ArticleItemProps>(({ articleItem, index }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [fullSize, setFullSize] = useState(false);
  const { input_text, visible_text, type, status, file_url, error_text } =
    articleItem;
  const [editedText, setEditedText] = useState(visible_text || "");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const itemTextRef = useRef<HTMLDivElement | null>(null);

  const isError = isErrorStatus(status);
  const isLoading = status !== "ready" && !isError;

  const polling = useCallback(() => {
    dispatch(getArticleItem(articleItem.id));
  }, [articleItem.id, dispatch]);

  usePolling(polling, isLoading);

  useEffect(() => {
    setEditedText(visible_text || input_text || "");
  }, [visible_text, input_text]);

  const startEditing = useCallback(() => {
    setIsEditing(true);
    setFullSize(false);
  }, []);
  const endEditing = useCallback(() => setIsEditing(false), []);
  const showFullSize = useCallback(() => setFullSize(true), []);
  const hideFullSize = useCallback(() => {
    setFullSize(false);
    const { current } = itemTextRef || {};
    if (current) current.scrollTo(0, 0);
  }, []);

  const changeText = useCallback(async () => {
    endEditing();
    if (!editedText) {
      setEditedText(visible_text || "");
      return;
    }
    if (visible_text === editedText) return;
    const favoriteResult = find(articleItem.article_item_results, "favorite");
    try {
      if (favoriteResult) {
        await dispatch(
          patchArticleItemResult({
            articleId: articleItem.article_id,
            articleItemId: articleItem.id,
            id: favoriteResult.id,
            change: { text: editedText },
          }),
        ).unwrap();
      } else {
        await dispatch(
          patchArticleItem({
            articleItem,
            change: { input_text: editedText },
          }),
        ).unwrap();
      }
    } catch (error) {
      setEditedText(visible_text || "");
    }
  }, [dispatch, endEditing, editedText, articleItem, visible_text]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) changeText();
    },
    [changeText],
  );

  const onDeleteClick = useCallback(() => {
    setDeleteConfirmOpen(true);
  }, []);

  const onDelete = useCallback(
    () => dispatch(deleteArticleItem(articleItem)),
    [articleItem, dispatch],
  );

  const openArticleItemModal = useCallback(() => {
    dispatch(articleItemModalChange(articleItem));
  }, [articleItem, dispatch]);

  const onRetry = useCallback(() => {
    dispatch(
      retryArticleItem({
        id: articleItem.id,
      }),
    );
  }, [articleItem.id, dispatch]);

  return (
    <>
      <Draggable draggableId={toString(articleItem.id)} index={index}>
        {(provided) => (
          <Item
            type={type}
            isEditing={isEditing}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={
              type === "live_audio"
                ? USETIFUL_STEP_CLASSNAMES.audioNote
                : undefined
            }
          >
            {file_url && (
              <AudioPanel
                type={type}
                src={file_url}
                isLoading={isLoading}
                fileName={articleItem.input_audio_name}
              />
            )}
            {isError ? (
              <Retry error={error_text} onRetry={onRetry} />
            ) : isEditing ? (
              <TextAreaContainer type={type}>
                <TextArea
                  value={editedText}
                  onChange={setEditedText}
                  isSubtitle={type === "subtitle"}
                  onBlur={changeText}
                  onKeyDown={onKeyDown}
                  maxHeight={type === "text" || type === "subtitle" ? 273 : 208}
                  autoFocus
                />
              </TextAreaContainer>
            ) : isLoading && !file_url ? (
              <LoaderContainer>
                <Loader />
                {t("dashboard.isCreating")}
              </LoaderContainer>
            ) : (
              !isLoading && (
                <ItemText
                  ref={itemTextRef}
                  fullSize={fullSize}
                  type={type}
                  onClick={openArticleItemModal}
                >
                  {visible_text || input_text}
                </ItemText>
              )
            )}
            {!isLoading && (
              <Control className="control">
                <ControlIcon className="icon-close" onClick={onDeleteClick} />
                {!isError && (
                  <ControlIcon className="icon-edit" onClick={startEditing} />
                )}
              </Control>
            )}
            {fullSize ? (
              <Hide onClick={hideFullSize}>
                Hide
                <i className="icon-arrow-up" />
              </Hide>
            ) : (
              !isEditing &&
              !isLoading &&
              !isError && <Gradient onClick={showFullSize} />
            )}
          </Item>
        )}
      </Draggable>
      <DeleteConfirm
        open={deleteConfirmOpen}
        onOpenChange={setDeleteConfirmOpen}
        onAccept={onDelete}
      />
    </>
  );
});

export default ArticleItem;
