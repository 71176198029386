import { defaultMimeType, drawRoundRect } from "../../utils";
import { useTheme } from "@emotion/react";
import { each, range } from "lodash";
import { useCallback, useEffect, useRef } from "react";

const CONSTRAINTS: MediaStreamConstraints = { audio: true };

let audioContext: AudioContext | null = null;

export const useAudioRecorder = () => {
  const theme = useTheme();

  const stream = useRef<MediaStream | null>(null);
  const recorder = useRef<MediaRecorder | null>(null);
  const chunks = useRef<Blob[]>([]);
  const raf = useRef<number | null>(null);

  const getStream = useCallback(async () => {
    stream.current =
      await window.navigator.mediaDevices.getUserMedia(CONSTRAINTS);
  }, []);

  const getAudioFile = useCallback(() => {
    const blob = new Blob(chunks.current, { type: defaultMimeType });
    chunks.current = [];
    return new Promise<string | ArrayBuffer | null>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }, []);

  const startRecording = useCallback(() => {
    if (!stream.current || !stream.current?.active) return;
    recorder.current = new MediaRecorder(stream.current);
    recorder.current.ondataavailable = (e) => {
      chunks.current.push(e.data);
    };
    recorder.current.start();
  }, []);

  const stopRecording = useCallback(() => {
    if (raf.current) cancelAnimationFrame(raf.current);
    return new Promise<string>((resolve, reject) => {
      if (!recorder.current) {
        reject();
        return;
      }
      recorder.current.onstop = () => {
        getAudioFile().then((url) => {
          if (typeof url === "string") resolve(url);
          else reject();
        });
      };
      recorder.current.onerror = reject;
      recorder.current.stop();
    });
  }, [getAudioFile]);

  const visualize = useCallback(
    (canvas: HTMLCanvasElement | null) => {
      if (!stream.current || !canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      if (!audioContext) audioContext = new window.AudioContext();

      const source = audioContext.createMediaStreamSource(stream.current);
      const analyser = audioContext.createAnalyser();
      source.connect(analyser);

      analyser.fftSize = 128;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const draw = () => {
        raf.current = requestAnimationFrame(draw);

        analyser.getByteFrequencyData(dataArray);

        ctx.fillStyle = theme.colors.lightgrey2;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const barWidth = canvas.width / bufferLength;
        let barHeight;
        let x = 0;

        each(range(bufferLength), (index) => {
          barHeight = dataArray[index] / 2 + 2;
          if (barHeight > canvas.height / 2) {
            barHeight = canvas.height / 2;
          }

          ctx.fillStyle = theme.colors.black;
          ctx.beginPath();
          drawRoundRect(
            ctx,
            x,
            canvas.height / 2 - barHeight / 2,
            barWidth,
            barHeight,
            3,
          );
          ctx.fill();

          x += barWidth + 1;
        });
      };

      draw();
    },
    [theme],
  );

  return {
    state: recorder.current?.state,
    startRecording,
    stopRecording,
    getStream,
    visualize,
  };
};

export const useScrollIntoView = <ElementType extends HTMLElement>() => {
  const ref = useRef<ElementType | null>(null);
  useEffect(() => {
    ref.current?.scrollIntoView(false);
  }, []);
  return ref;
};

export const usePolling = (fn: () => void, enabled = false, delay = 3000) => {
  const pollingInterval = useRef<number>();

  useEffect(() => {
    if (enabled) {
      pollingInterval.current = window.setInterval(() => {
        fn();
      }, delay);
    } else {
      window.clearInterval(pollingInterval.current);
    }
    return () => {
      window.clearInterval(pollingInterval.current);
    };
  }, [delay, enabled, fn]);

  return pollingInterval.current;
};
